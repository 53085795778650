'use client';

import colors from '@/assets/scss/_themes-vars.module.scss';
import { createTheme } from '@mui/material/styles';
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

const color = colors;

const themeOption = {
  colors: color,
  heading: color.grey900,
  paper: color.paper,
  backgroundDefault: color.paper,
  background: color.primaryLight,
  darkTextPrimary: color.grey700,
  darkTextSecondary: color.grey500,
  textDark: color.grey900,
  menuSelected: color.secondaryDark,
  menuSelectedBack: color.primaryLight,
  divider: color.grey200,
};

export const theme = createTheme({
  palette: themePalette(themeOption),
  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: '48px',
      padding: '16px',
      '@media (min-width: 600px)': {
        minHeight: '48px',
      },
    },
  },
  typography: themeTypography(themeOption),

  components: componentStyleOverrides(themeOption),
});

export default theme;
