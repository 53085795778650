// 'use client';

// import { store } from '@/store/store'; // Adjust the import based on your store's file location
// import { useRef } from 'react';
// import { Provider } from 'react-redux';
// import { persistStore } from 'redux-persist';
// import { PersistGate } from 'redux-persist/integration/react';

// export default function ReduxProvider({ children }) {
//   const persistorRef = useRef();
//   if (!persistorRef.current) {
//     // Create the persistor instance the first time this renders
//     // Note: We're using the existing store instance imported directly,
//     // so no need to recreate it with makeStore() in this context
//     persistorRef.current = persistStore(store);
//   }

//   return (
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistorRef.current}>
//         {children}
//       </PersistGate>
//     </Provider>
//   );
// }

// 'use client';

// import { makeStore } from '@/store/store';
// import { useRef } from 'react';
// import { Provider } from 'react-redux';

// export default function ReduxProvider({ children, pageProps }) {
//   const storeRef = useRef();
//   if (!storeRef.current) {
//     // Create the store instance the first time this renders
//     storeRef.current = makeStore();
//   }

//   return <Provider store={storeRef.current}>{children}</Provider>;
// }

// Assuming this import is correct and points to where your store is configured

'use client';
import LogoLoader from '@/components/loadingComponets/LogoLoader';
import { makeStore } from '@/store/store';
import { useRef } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

export default function ReduxProvider({ children }) {
  const storeRef = useRef();
  const persistorRef = useRef();

  if (!storeRef.current) {
    // Create the store and persistor instance the first time this component renders
    storeRef.current = makeStore();
    persistorRef.current = persistStore(storeRef.current);
  }

  return (
    <Provider store={storeRef.current}>
      <PersistGate loading={<LogoLoader />} persistor={persistorRef.current}>
        {children}
      </PersistGate>
    </Provider>
  );
}
